import React from 'react'
import Helmet from 'react-helmet'

import defaultFavicon from '../../assets/images/favicon.png'
import { useGlobalData } from '../../contexts/GlobalData'
import loadingIcon from '../../assets/images/loader_thin.svg'

export const CustomHeaders = () => {
  const { wlConfig } = useGlobalData()

  if (!wlConfig) {
    return (
      <Helmet>
        <title>Loading...</title>
        <link rel="icon" href={loadingIcon} sizes="32x32" />
      </Helmet>
    )
  }

  return (
    <Helmet>
      <title>{wlConfig.title || 'IX Swap | Insights'}</title>
      <link rel="icon" href={wlConfig.faviconUrl || defaultFavicon} sizes="32x32" />
    </Helmet>
  )
}
