import React from 'react'
import { ReactComponent as LoadingThin } from '../../assets/images/loader_thin.svg'
import styled, { css, keyframes } from 'styled-components'
import { SvgIconWrapper } from '../../Theme/components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledRotate = styled.div`
  animation: 2s ${rotate} linear infinite;
`

const StyledLoading = styled(LoadingThin)<{ size?: number }>`
  height: ${({ size }) => (size ? size + 'px' : '32px')};
  width: ${({ size }) => (size ? size + 'px' : '32px')};
  path {
    ${({ theme }) =>
      theme.config.elements?.hover &&
      css`
        fill: ${({ theme }) => `${theme.config.elements.hover} !important`};
      `};
  }
`

/**
 * Takes in custom size and stroke for circle color, default to primary color as fill,
 * need ...rest for layered styles on top
 */
export const LoaderThin = ({ size = 16 }: { size?: number; stroke?: string; thin?: boolean; [k: string]: any }) => {
  return (
    <StyledRotate>
      <SvgIconWrapper size={size}>
        <StyledLoading size={size} />
      </SvgIconWrapper>
    </StyledRotate>
  )
}
