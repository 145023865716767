import 'feather-icons'
import React, { useEffect, useMemo, useState } from 'react'
import { Flex, Text } from 'rebass'
import styled from 'styled-components'
import { StringParam, useQueryParams } from 'use-query-params'
import { FullWrapper, PageWrapper } from '../components'
import Column from '../components/Column'
import Header from '../components/Header'

import { Input } from '../components/Input'
import { PairsTable } from '../components/PairsTable'
import { ActiveSortItemBottom, SortText } from '../components/PairTransactionsTable'
import Row, { RowFixed } from '../components/Row'
import { useGlobalData } from '../contexts/GlobalData'
import { usePairs } from '../contexts/PairData'
import { TYPE } from '../Theme'
import { Container } from '../Theme/wrappers'
import { searchPairs } from '../utils/filter'
import { PairTabs } from './AllPairsPage'
import LocalLoader from '../components/LocalLoader'
import { LoaderThin } from '../components/LoaderThin'
import { BasicLink } from '../components/Link'
import { formatAmount } from '../utils/formatAmount'
export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;

  @media screen and (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`
export const pairTabFilters = [PairTabs.SECURITY, PairTabs.CRYPTO]

function AllPoolsPage({ type = PairTabs.CRYPTO }) {
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSearchChange = (e) => {
    const search = e.currentTarget.value
    setSearchValue(search)
    const normalizedSearch = search.trim()?.toLowerCase()
    if (normalizedSearch.length === 0) {
      setFilteredPairs({})
    }
    if (normalizedSearch?.length < 2) {
      return
    }
    const newPairs = searchPairs(usedPairs, normalizedSearch)
    if (newPairs) {
      setFilteredPairs(newPairs)
    }
  }

  const { secPairs = {}, cryptoPairs = {} } = usePairs()
  const usedPairs = useMemo(() => {
    if (type === PairTabs.SECURITY) {
      return secPairs
    }
    return cryptoPairs
  }, [cryptoPairs, secPairs, type])

  const [filteredPairs, setFilteredPairs] = useState({})

  const visiblePools = Object.keys(filteredPairs).length || searchValue.length ? filteredPairs : usedPairs
  const { totalValueLockedSecurity, totalValueLockedCrypto } = useGlobalData()
  const tvl = useMemo(() => {
    switch (type) {
      case PairTabs.SECURITY:
        return totalValueLockedSecurity
      default:
        return totalValueLockedCrypto
    }
  }, [type, totalValueLockedCrypto, totalValueLockedSecurity])
  return (
    <>
      <Header />
      <Container width={['100%']} maxWidth={'1299px'} margin={'auto'}>
        <PageWrapper>
          <FullWrapper>
            <TYPE.primary fontSize={36} lineHeight="56px">
              {type} pools
            </TYPE.primary>
            <TYPE.lightText fontWeight={300} marginBottom="9px"></TYPE.lightText>

            <RowFixed style={{ marginBottom: '45px' }}>
              <Column style={{ marginRight: '46px' }}>
                <TYPE.primary style={{ textTransform: 'uppercase' }} fontWeight={500} fontSize={18} lineHeight="27px">
                  Total Value locked:
                </TYPE.primary>
                {visiblePools && Object.keys(visiblePools)?.length !== 0 && (
                  <TYPE.primary fontSize={36} lineHeight="56px">
                    ${formatAmount(tvl, 0, 0)}
                  </TYPE.primary>
                )}
                {!visiblePools ||
                  (Object.keys(visiblePools)?.length === 0 && (
                    <Row marginTop={3}>
                      <LoaderThin size={32} />
                    </Row>
                  ))}
              </Column>
              {/* <Column>
              <TYPE.primary style={{ textTransform: 'uppercase' }} fontWeight={500} fontSize={18} lineHeight="27px">
                My ETH Rewards
              </TYPE.primary>
              <TYPE.primary fontSize={36} lineHeight="56px">
                0.015
              </TYPE.primary>
            </Column> */}
            </RowFixed>
            <Input marginBottom="45px" value={searchValue} placeholder="Search pools" onChange={onSearchChange} />
            <Flex alignItems="center">
              <BasicLink key={`sort-item-${PairTabs.SECURITY}`} to="/pools/security">
                <Column>
                  <SortText active={type === PairTabs.SECURITY}>
                    <Text fontSize={20}>{PairTabs.SECURITY}</Text>
                  </SortText>
                  {type === PairTabs.SECURITY && <ActiveSortItemBottom />}
                </Column>
              </BasicLink>
              <BasicLink key={`sort-item-${PairTabs.CRYPTO}`} to="/pools/crypto">
                <Column>
                  <SortText active={type === PairTabs.CRYPTO}>
                    <Text fontSize={20}>{PairTabs.CRYPTO}</Text>
                  </SortText>
                  {type === PairTabs.CRYPTO && <ActiveSortItemBottom />}
                </Column>
              </BasicLink>
            </Flex>
            {visiblePools && Object.keys(visiblePools)?.length !== 0 && (
              <PairsTable items={Object.values(visiblePools)} />
            )}
            {!visiblePools ||
              (Object.keys(visiblePools)?.length === 0 && (
                <Row>
                  <LocalLoader />
                </Row>
              ))}
          </FullWrapper>
        </PageWrapper>
      </Container>
    </>
  )
}

export default AllPoolsPage
