import React from 'react'
import { ThemeProvider as StyledComponentsThemeProvider, createGlobalStyle } from 'styled-components'
import { useDarkModeManager } from '../contexts/LocalStorage'
import styled, { css } from 'styled-components'
import { Text } from 'rebass'
import { useGlobalData } from '../contexts/GlobalData'

export default function ThemeProvider({ children }) {
  const { wlConfig } = useGlobalData()
  const [darkMode] = useDarkModeManager()

  return (
    <StyledComponentsThemeProvider theme={theme(darkMode, wlConfig?.colors)}>{children}</StyledComponentsThemeProvider>
  )
}

const theme = (darkMode, configColors) => {
  const wlColors = configColors || {}

  return {
    config: wlColors,
    textColor: wlColors.text?.main || (darkMode ? 'white' : 'black'),

    panelColor: darkMode ? 'rgba(255, 255, 255, 0)' : 'rgba(255, 255, 255, 0)',
    backgroundColor: wlColors.background?.main || (darkMode ? '#212429' : '#F7F8FA'),

    ixswapPink: darkMode ? '#ff007a' : 'black',

    concreteGray: darkMode ? '#292C2F' : '#FAFAFA',
    inputBackground: darkMode ? '#1F1F1F' : '#FAFAFA',
    shadowColor: darkMode ? '#000' : '#2F80ED',
    mercuryGray: darkMode ? '#333333' : '#E1E1E1',

    text1: wlColors.text?.main || (darkMode ? '#FAFAFA' : '#1F1F1F'),
    text2: wlColors.text?.additional1 || (darkMode ? '#C3C5CB' : '#565A69'),
    text3: wlColors.text?.main || (darkMode ? '#6C7284' : '#888D9B'),
    text4: wlColors.text?.main || (darkMode ? '#565A69' : '#C3C5CB'),
    text5: wlColors.text?.main || (darkMode ? '#2C2F36' : '#EDEEF2'),
    text6: wlColors.text?.main || (darkMode ? '#EDCEFF' : '#EDCEFF'),
    text7: wlColors.text?.additional1 || '#EDCEFF80',

    // special case text types
    customWhite: wlColors.text?.main || '#FFFFFF',

    // backgrounds / greys
    bg1: wlColors.background?.secondary || (darkMode ? '#212429' : '#FAFAFA'),
    bg2: darkMode ? '#2C2F36' : '#F7F8FA',
    bg3: darkMode ? '#40444F' : '#EDEEF2',
    bg4: darkMode ? '#565A69' : '#CED0D9',
    bg5: darkMode ? '#565A69' : '#888D9B',
    bg6: wlColors.background?.main || (darkMode ? '#000' : '#FFFFFF'),
    bg7: wlColors.background?.main || (darkMode ? '#372E5E' : '#372E5E'),
    bg8: wlColors.background?.main || (darkMode ? '#271F4A' : '#271F4A'),
    bg9: wlColors.background?.main || (darkMode ? '#1A123A' : '#1A123A'),
    //specialty colors
    modalBG: darkMode ? 'rgba(0,0,0,0.85)' : 'rgba(0,0,0,0.6)',
    advancedBG: wlColors.background?.main || (darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.4)'),
    onlyLight: wlColors.background?.secondary || (darkMode ? '#0D0415' : 'transparent'),
    divider: wlColors.background?.main || (darkMode ? 'rgba(43, 43, 43, 0.435)' : 'rgba(43, 43, 43, 0.035)'),

    //primary colors
    primary1: wlColors.primary?.main || (darkMode ? '#2172E5' : '#ff007a'),
    primary2: wlColors.primary?.additional1 || (darkMode ? '#3680E7' : '#FF8CC3'),
    primary3: wlColors.primary?.additional1 || (darkMode ? '#4D8FEA' : '#FF99C9'),
    primary4: wlColors.primary?.additional1 || (darkMode ? '#376bad70' : '#F6DDE8'),
    primary5: darkMode ? '#153d6f70' : '#FDEAF1',

    // color text
    primaryText1: darkMode ? '#6da8ff' : '#ff007a',

    // secondary colors
    secondary1: darkMode ? '#2172E5' : '#ff007a',
    secondary2: darkMode ? '#17000b26' : '#F6DDE8',
    secondary3: darkMode ? '#17000b26' : '#FDEAF1',

    shadow1: darkMode ? '#000' : '#2F80ED',

    // other
    red1: wlColors.status?.error || '#FF6871',
    green1: wlColors.status?.success || '#27AE60',
    yellow1: wlColors.status?.warning || '#FFE270',
    yellow2: wlColors.status?.warning || '#F3841E',
    link: '#2172E5',
    blue: wlColors.status?.info || '2f80ed',
    blue1: wlColors.status?.info || '#5048E5',

    borderG1:
      wlColors.background?.main ||
      (darkMode
        ? 'linear-gradient(116.36deg, #7b42a9 33.43%, #ed0376 95.41%);'
        : 'linear-gradient(116.36deg, #7b42a9 33.43%, #ed0376 95.41%);'),

    background:
      wlColors.background?.main || (darkMode ? 'black' : `radial-gradient(50% 50% at 50% 50%, #ff007a30 0%, #fff 0%)`),
    background1:
      wlColors.background?.main ||
      (darkMode
        ? 'linear-gradient(116.36deg, rgb(123, 66, 169) 33.43%, rgb(237, 3, 118) 95.41%), rgb(12, 70, 156)'
        : 'linear-gradient(116.36deg, rgb(123, 66, 169) 33.43%, rgb(237, 3, 118) 95.41%), rgb(12, 70, 156)'),

    bgG1:
      wlColors.background?.main ||
      (darkMode
        ? 'radial-gradient(93.65% 93.65% at 58.57% 22.42%, rgba(206, 20, 132, 0.33) 0%, rgba(26, 18, 58, 0) 100%), #2C254A;'
        : 'radial-gradient(93.65% 93.65% at 58.57% 22.42%, rgba(206, 20, 132, 0.33) 0%, rgba(26, 18, 58, 0) 100%), #2C254A;'),

    bgG2:
      wlColors.background?.main ||
      'radial-gradient(93.65% 93.65% at 58.57% 22.42%, rgba(206, 20, 132, 0.033) 0%, rgba(26, 18, 58, 0) 100%), rgba(44, 37, 74, 0.5);',
    bgG3:
      wlColors.background?.secondary ||
      (darkMode
        ? 'linear-gradient(116.36deg, #7B42A9 33.43%, #ED0376 95.41%), #0C469C;'
        : 'linear-gradient(116.36deg, #7B42A9 33.43%, #ED0376 95.41%), #0C469C;'),
  }
}

const TextWrapper = styled(Text)`
  color: ${({ color, theme }) => theme[color]};
`

export const TYPE = {
  main(props) {
    return <TextWrapper fontWeight={500} fontSize={14} color={'text1'} {...props} />
  },

  primary(props) {
    return <TextWrapper fontWeight={600} fontSize={22} lineHeight="33px" color={'text1'} {...props} />
  },

  body(props) {
    return <TextWrapper fontWeight={400} fontSize={14} color={'text1'} {...props} />
  },

  small(props) {
    return <TextWrapper fontWeight={500} fontSize={11} color={'text1'} {...props} />
  },

  header(props) {
    return <TextWrapper fontWeight={600} color={'text1'} {...props} />
  },

  largeHeader(props) {
    return <TextWrapper fontWeight={500} color={'text1'} fontSize={24} {...props} />
  },

  light(props) {
    return <TextWrapper fontWeight={400} color={'text3'} fontSize={14} {...props} />
  },

  lightText(props) {
    return <TextWrapper fontWeight={400} color={'text6'} fontSize={16} lineHeight="24px" {...props} />
  },

  pink(props) {
    return <TextWrapper fontWeight={props.faded ? 400 : 600} color={props.faded ? 'text1' : 'text1'} {...props} />
  },

  gradient(props) {
    return <TextWrapper color={`background1`} {...props} />
  },
  main1(props) {
    return <TextWrapper fontWeight={600} fontSize={20} lineHeight={'30px'} color={'text1'} {...props} />
  },
}

export const Hover = styled.div`
  :hover {
    cursor: pointer;
  }
`

export const gradientBorder = css`
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 45px;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    padding: 2px;
    background: ${({ theme }) => theme.borderG1};
  }
`

export const Link = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme }) => theme.primary1};
  font-weight: 500;
  :hover {
    text-decoration: underline;
  }
  :focus {
    outline: none;
    text-decoration: underline;
  }
  :active {
    text-decoration: none;
  }
`

export const ThemedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  max-width: 100vw !important;
  height: 200vh;
  mix-blend-mode: color;
  background: ${({ backgroundColor }) =>
    `radial-gradient(50% 50% at 50% 50%, ${backgroundColor} 0%, rgba(255, 255, 255, 0) 100%)`};
  position: absolute;
  top: 0px;
  left: 0px;
  /* z-index: ; */

  transform: translateY(-110vh);
`

export const GlobalStyle = createGlobalStyle`
  @import url('https://rsms.me/inter/inter.css');
  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
  
  html,
  body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-size: 14px;    
    background-color: ${({ theme }) => theme.bg6};
  }

  a {
    text-decoration: none;

    :hover {
      text-decoration: none
    }
  }

  
.three-line-legend {
	width: 100%;
	height: 70px;
	position: absolute;
	padding: 8px;
	font-size: 12px;
	color: #20262E;
	background-color: rgba(255, 255, 255, 0.23);
	text-align: left;
	z-index: 10;
  pointer-events: none;
}

.three-line-legend-dark {
	width: 100%;
	height: 70px;
	position: absolute;
	padding: 8px;
	font-size: 12px;
	color: white;
	background-color: rgba(255, 255, 255, 0.23);
	text-align: left;
	z-index: 10;
  pointer-events: none;
}

@media screen and (max-width: 800px) {
  .three-line-legend {
    display: none !important;
  }
}

.tv-lightweight-charts{
  width: 100% !important;
  

  & > * {
    width: 100% !important;
  }
}


  html {
    font-size: 1rem;
    font-variant: none;
    color: 'black';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
  }
`
