import React, { useEffect, useState } from 'react'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { QueryParamProvider } from 'use-query-params'
import { client } from './apollo/client'
import GoogleAnalyticsReporter from './components/analytics/GoogleAnalyticsReporter'
import { LoaderThin } from './components/LoaderThin'
import LocalLoader from './components/LocalLoader'
import { PAIR_BLACKLIST, TOKEN_BLACKLIST } from './constants'
import { useLatestBlocks } from './contexts/Application'
import { useGetWlConfig, useGlobalChartData, useGlobalData } from './contexts/GlobalData'
import AccountPage from './pages/AccountPage'
import AllPairsPage, { PairTabs } from './pages/AllPairsPage'
import AllPoolsPage from './pages/AllPoolsPage'
import AllTokensPage from './pages/AllTokensPage'
import PairPage from './pages/PairPage'
import PoolPage from './pages/PoolPage'
import TokenPage from './pages/TokenPage'
import { isAddress } from './utils'

const AppWrapper = styled.div``
const ContentWrapper = styled.div`
  padding-top: 50px;

  @media screen and (max-width: 1080px) {
    max-width: 100vw;
    overflow: hidden;
  }
`

const Center = styled.div`
  z-index: 9999;
  transition: width 0.25s ease;
  background-color: ${({ theme }) => theme.onlyLight};
`

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const WarningBanner = styled.div`
  background-color: #ff6871;
  padding: 1.5rem;
  color: white;
  width: 100%;
  text-align: center;
  font-weight: 500;
`

/**
 * Wrap the component with the header and sidebar pinned tab
 */
const LayoutWrapper = ({ children, savedOpen, setSavedOpen }) => {
  return (
    <>
      <ContentWrapper open={savedOpen}>
        <Center id="center">{children}</Center>
      </ContentWrapper>
    </>
  )
}

const BLOCK_DIFFERENCE_THRESHOLD = 30

function App() {
  const getWlConfig = useGetWlConfig()
  const [savedOpen, setSavedOpen] = useState(false)

  const globalData = useGlobalData()
  const globalChartData = useGlobalChartData()
  const [latestBlock, headBlock] = useLatestBlocks()

  // show warning
  const showWarning = headBlock && latestBlock ? headBlock - latestBlock > BLOCK_DIFFERENCE_THRESHOLD : false

  useEffect(() => {
    getWlConfig()
  }, [getWlConfig])

  if (!globalData?.wlConfig) {
    return <LoaderThin />
  }

  return (
    <ApolloProvider client={client}>
      <AppWrapper>
        {showWarning && (
          <WarningWrapper>
            <WarningBanner>
              {`Warning: The data on this site has only synced to Ethereum block ${latestBlock} (out of ${headBlock}). Please check back soon.`}
            </WarningBanner>
          </WarningWrapper>
        )}
        {globalData &&
        Object.keys(globalData).length > 0 &&
        globalChartData &&
        Object.keys(globalChartData).length > 0 ? (
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
              <Route component={GoogleAnalyticsReporter} />
              <Switch>
                <Route
                  exacts
                  strict
                  path="/token/:tokenAddress"
                  render={({ match }) => {
                    if (
                      isAddress(match.params.tokenAddress?.toLowerCase()) &&
                      !Object.keys(TOKEN_BLACKLIST).includes(match.params.tokenAddress?.toLowerCase())
                    ) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <TokenPage address={match.params.tokenAddress?.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />
                <Route path="/pools/security">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllPoolsPage type={PairTabs.SECURITY} />
                  </LayoutWrapper>
                </Route>
                <Route path="/pools/crypto">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllPoolsPage />
                  </LayoutWrapper>
                </Route>
                <Route
                  exacts
                  strict
                  path="/pair/:pairAddress"
                  render={({ match }) => {
                    if (
                      isAddress(match.params.pairAddress?.toLowerCase()) &&
                      !Object.keys(PAIR_BLACKLIST).includes(match.params.pairAddress?.toLowerCase())
                    ) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <PairPage pairAddress={match.params.pairAddress?.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />
                <Route
                  exacts
                  strict
                  path="/pools/:pairAddress"
                  render={({ match }) => {
                    if (
                      isAddress(match.params.pairAddress?.toLowerCase()) &&
                      !Object.keys(PAIR_BLACKLIST).includes(match.params.pairAddress?.toLowerCase())
                    ) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <PoolPage pairAddress={match.params.pairAddress?.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />
                <Route
                  exacts
                  strict
                  path="/account/:accountAddress"
                  render={({ match }) => {
                    if (isAddress(match.params.accountAddress?.toLowerCase())) {
                      return (
                        <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                          <AccountPage account={match.params.accountAddress?.toLowerCase()} />
                        </LayoutWrapper>
                      )
                    } else {
                      return <Redirect to="/home" />
                    }
                  }}
                />

                <Route path="/home">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllPairsPage />
                  </LayoutWrapper>
                </Route>
                <Route path="/tokens">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllTokensPage />
                  </LayoutWrapper>
                </Route>

                <Route path="/pairs">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AllPairsPage />
                  </LayoutWrapper>
                </Route>

                {/* <Route path="/accounts">
                  <LayoutWrapper savedOpen={savedOpen} setSavedOpen={setSavedOpen}>
                    <AccountLookup />
                  </LayoutWrapper>
                </Route> */}

                <Redirect to="/home" />
              </Switch>
            </QueryParamProvider>
          </BrowserRouter>
        ) : (
          <LocalLoader fill="true" />
        )}
      </AppWrapper>
    </ApolloProvider>
  )
}

export default App
