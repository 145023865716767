import React, { FC, useState } from 'react'
import styled from 'styled-components'

import { BodyRow, Table } from '../Table'
import { TYPE } from '../../Theme'
import { Pagination } from '../Pagination'
import { HeaderRow } from '../Table'
import { RowFixed } from '../Row'
import Column from '../Column'
import { IPoolToken, NoData, Container } from '../PairsTable'
import { getTransactionType } from '../TxnList'
import { formattedNum, formatTime } from '../../utils'

export const SortText = styled.button<{ active?: boolean }>`
  ${({ active, theme: { text1, text3 } }: { active: boolean; theme: { text1: string; text3: string } }) => `
  cursor: pointer;
  font-weight: ${active ? 600 : 300};
  margin-right: 0.75rem !important;
  border: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  padding: 0px;
  color: ${active ? text1 : text3};
  margin-top: ${active ? '4px' : '0px'};
  outline: none;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  `}
`

export const ActiveSortItemBottom = styled.div`
  height: 4px;
  background: ${({ theme }) => theme.borderG1};
  width: calc(100% - 0.75rem);
`

const headerCells = ['Total value', 'Token amount', 'Token amount', 'Account', 'Time']

const TXN_TYPE = {
  ALL: 'All',
  SWAP: 'Swap',
  ADD: 'Mint',
  REMOVE: 'Burn',
}

const sortItems = [
  {
    title: 'All',
    value: TXN_TYPE.ALL,
  },
  {
    title: 'Swaps',
    value: TXN_TYPE.SWAP,
  },
  {
    title: 'Adds',
    value: TXN_TYPE.ADD,
  },
  {
    title: 'Removes',
    value: TXN_TYPE.REMOVE,
  },
]
interface IPairTransaction {
  __typename: string
  amount0?: string
  amount1?: string
  amount0In?: string
  amount0Out?: string
  amount1In?: string
  amount1Out?: string
  amountUSD: string
  pair: {
    token0: IPoolToken
    token1: IPoolToken
  }
  sender?: string
  to?: string
  transaction: {
    id: string
    timestamp: string
  }
}

interface BodyProps extends TableProps {
  page: number
  pageLimit: number
}

interface HeaderProps {
  txFilter: string
  handleFilterChange: (newTxFilter: string) => void
}

interface TableProps {
  items: IPairTransaction[]
}

const Header: FC<HeaderProps> = ({ txFilter, handleFilterChange }: HeaderProps) => {
  return (
    <StyledHeaderRow>
      <RowFixed area="txn" gap="10px" pl={4}>
        {sortItems.map(({ title, value }) => (
          <Column key={`sort-item-${value}`}>
            <SortText
              onClick={() => {
                handleFilterChange(value)
              }}
              active={txFilter === value}
            >
              {title}
            </SortText>
            {txFilter === value && <ActiveSortItemBottom />}
          </Column>
        ))}
      </RowFixed>
      {headerCells.map((cell, id) => (
        <div key={`${cell}-${id}`}>{cell}</div>
      ))}
    </StyledHeaderRow>
  )
}

const Body: FC<BodyProps> = ({ items, page, pageLimit }: BodyProps) => {
  return (
    <>
      {items
        .slice((page - 1) * pageLimit, page * pageLimit)
        .map(
          ({
            __typename,
            amount0,
            amount1,
            amount0In,
            amount0Out,
            amount1In,
            amount1Out,
            amountUSD,
            pair,
            transaction,
            to,
            sender,
          }) => (
            <StyledBodyRow key={`pair-transction-table-${transaction.id}`}>
              <div>
                <TYPE.body fontSize={18} lineHeight="27px">
                  {getTransactionType(
                    __typename,
                    amount0In === '0' || amount0In === undefined ? pair.token1.symbol : pair.token0.symbol,
                    amount0In === '0' || amount0In === undefined ? pair.token0.symbol : pair.token1.symbol
                  )}
                </TYPE.body>
              </div>
              <div>
                <TYPE.body fontSize={18} lineHeight="27px">
                  {`${formattedNum(amountUSD, true)}`}
                </TYPE.body>
              </div>
              <div>
                <TYPE.body fontSize={18} lineHeight="27px">
                  {`${formattedNum(
                    amount0 === '0' || amount0 === undefined ? (amount0In !== '0' ? amount0In : amount0Out) : amount0
                  )} ${pair.token0.symbol}`}
                </TYPE.body>
              </div>
              <div>
                <TYPE.body fontSize={18} lineHeight="27px">
                  {`${formattedNum(
                    amount1 === '0' || amount1 === undefined ? (amount1In !== '0' ? amount1In : amount1Out) : amount1
                  )} ${pair.token1.symbol}`}
                </TYPE.body>
              </div>
              <div>
                <Wallet>
                  <TYPE.body color="inherit" fontSize={18} lineHeight="27px">
                    {sender && sender.slice(0, 6) + '...' + sender.slice(38, 42)}
                    {to && to.slice(0, 6) + '...' + to.slice(38, 42)}
                  </TYPE.body>
                </Wallet>
              </div>
              <div>
                <TYPE.body fontSize={18} lineHeight="27px">
                  {`${formatTime(+transaction.timestamp)}`}
                </TYPE.body>
              </div>
            </StyledBodyRow>
          )
        )}
    </>
  )
}

export const PairTransactionsTable: FC<TableProps> = ({ items }: TableProps) => {
  const [page, setPage] = useState(1)
  const [filteredItems, setFilteredItems] = useState(items)
  const pageLimit = 10
  const [totalPages, setTotalPages] = useState(Math.ceil(items.length / pageLimit))
  const [txFilter, setTxFilter] = useState(TXN_TYPE.ALL)

  const handleFilterChange = (newTxFilter: string) => {
    if (txFilter === newTxFilter) return
    const newFilteredItems =
      newTxFilter === TXN_TYPE.ALL ? items : items.filter(({ __typename }) => __typename === newTxFilter)

    setPage(1)
    setFilteredItems(newFilteredItems)
    setTotalPages(Math.ceil(newFilteredItems.length / pageLimit))
    setTxFilter(newTxFilter)
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage)
  }

  return (
    <>
      {items.length === 0 ? (
        <NoData>No data</NoData>
      ) : (
        <Container>
          <Table
            body={<Body page={page} pageLimit={pageLimit} items={filteredItems} />}
            header={<Header txFilter={txFilter} handleFilterChange={handleFilterChange} />}
          />
          <Pagination page={page} totalPages={totalPages} onPageChange={onPageChange} />
        </Container>
      )}
    </>
  )
}

export const StyledHeaderRow = styled(HeaderRow)`
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  min-width: 1170px;
`

const StyledBodyRow = styled(BodyRow)`
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  min-width: 1170px;
  background: transparent;
  margin-bottom: 0px;
  border: none;

  > div {
    display: flex;
    justify-content: start;
  }
`

const Wallet = styled.div`
  background: ${({ theme: { background1 } }) => background1};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: none;
`
