import 'feather-icons'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FullWrapper, PageWrapper } from '../components'
import { ButtonGradientBorder } from '../components/ButtonStyled'
import Column from '../components/Column'
import { Input } from '../components/Input'
import { BasicLink } from '../components/Link'
import PoolCard, { Divider } from '../components/PoolCard'
import Row, { RowFixed } from '../components/Row'
import { useGlobalData } from '../contexts/GlobalData'
import { usePairs } from '../contexts/PairData'
import { TYPE } from '../Theme'
import { Container } from '../Theme/wrappers'
import { searchPairs } from '../utils/filter'
import Header from '../components/Header'
import LocalLoader from '../components/LocalLoader'
import { LoaderThin } from '../components/LoaderThin'
import { formatAmount } from '../utils/formatAmount'

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px;

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`
export const PairTabs = {
  CRYPTO: 'Crypto',
  SECURITY: 'Security',
  ALL: 'All',
}

function AllPairsPage() {
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const { totalValueLocked, totalValueLockedSecurity, totalValueLockedCrypto } = useGlobalData()
  const { secPairs = {}, cryptoPairs = {} } = usePairs()

  const [filteredSecPairs, setFilteredSecPairs] = useState({})
  const [filteredCryptoPairs, setFilteredCryptoPairs] = useState({})

  const onSearchChange = (e) => {
    const search = e.currentTarget.value
    setSearchValue(search)
    const normalizedSearch = search.trim()?.toLowerCase()
    if (normalizedSearch.length === 0) {
      setFilteredSecPairs({})
      setFilteredCryptoPairs({})
    }
    if (normalizedSearch?.length < 2) {
      return
    }
    const newSec = searchPairs(secPairs, normalizedSearch)
    const newCrypto = searchPairs(cryptoPairs, normalizedSearch)
    if (newSec) {
      setFilteredSecPairs(newSec)
    }
    if (newCrypto) {
      setFilteredCryptoPairs(newCrypto)
    }
  }

  const visibleSec = Object.keys(filteredSecPairs).length || searchValue.length ? filteredSecPairs : secPairs
  const visibleCrypto =
    Object.keys(filteredCryptoPairs).length || searchValue.length ? filteredCryptoPairs : cryptoPairs
  const showSec = Object.keys(visibleSec).length
  const showCrypto = Object.keys(visibleCrypto).length
  return (
    <>
      <Header />
      <Container width={['100%']} maxWidth={'1299px'} margin={'auto'}>
        <PageWrapper>
          <FullWrapper>
            <Input value={searchValue} placeholder="Search pools" onChange={onSearchChange} />

            <>
              <TYPE.primary fontSize={36} lineHeight="56px">
                Security pools
              </TYPE.primary>
              <TYPE.lightText fontWeight={300} marginBottom="9px"></TYPE.lightText>

              <RowFixed style={{ marginBottom: '21px' }}>
                <Column style={{ marginRight: '46px' }}>
                  <TYPE.primary style={{ textTransform: 'uppercase' }} fontWeight={500} fontSize={18} lineHeight="27px">
                    Total Value locked:
                  </TYPE.primary>
                  {Boolean(showSec) && (
                    <TYPE.primary fontSize={36} lineHeight="56px">
                      ${formatAmount(+totalValueLockedSecurity.toFixed(0))}
                    </TYPE.primary>
                  )}

                  {!showSec && (
                    <Row marginTop={3}>
                      <LoaderThin size={32} />
                    </Row>
                  )}
                </Column>
                {/* <Column>
                  <TYPE.primary style={{ textTransform: 'uppercase' }} fontWeight={500} fontSize={18} lineHeight="27px">
                    My ETH Rewards
                  </TYPE.primary>
                  <TYPE.primary fontSize={36} lineHeight="56px">
                    0.015
                  </TYPE.primary>
                </Column> */}
              </RowFixed>
              {Boolean(showSec) && (
                <Grid>
                  {Object.keys(visibleSec)
                    .slice(0, 4)
                    .map((item, index) => (
                      <PoolCard key={`pool-${index}-all`} item={visibleSec[item]} allPairs={visibleSec} />
                    ))}
                </Grid>
              )}

              {!showSec && (
                <Row>
                  <Column style={{ alignItems: 'center', width: '100%' }}>
                    <LocalLoader />
                  </Column>
                </Row>
              )}
              <BasicLink to={`/pools/security`} style={{ margin: '48px auto 36px' }}>
                <ButtonGradientBorder>
                  <TYPE.primary fontSize={18} lineHeight="20px">
                    Show all
                  </TYPE.primary>
                </ButtonGradientBorder>
              </BasicLink>

              <Divider />
            </>

            <>
              <TYPE.primary fontSize={36} lineHeight="56px">
                Crypto pools
              </TYPE.primary>
              <TYPE.lightText fontWeight={300} marginBottom="9px"></TYPE.lightText>

              <RowFixed style={{ marginBottom: '21px' }}>
                <Column style={{ marginRight: '46px' }}>
                  <TYPE.primary style={{ textTransform: 'uppercase' }} fontWeight={500} fontSize={18} lineHeight="27px">
                    Total Value locked:
                  </TYPE.primary>
                  {Boolean(showCrypto) && (
                    <TYPE.primary fontSize={36} lineHeight="56px">
                      ${formatAmount(+totalValueLockedCrypto.toFixed(0))}
                    </TYPE.primary>
                  )}

                  {!showCrypto && (
                    <Row marginTop={3}>
                      <LoaderThin size={32} />
                    </Row>
                  )}
                </Column>
                {/* <Column>
                  <TYPE.primary style={{ textTransform: 'uppercase' }} fontWeight={500} fontSize={18} lineHeight="27px">
                    My ETH Rewards
                  </TYPE.primary>
                  <TYPE.primary fontSize={36} lineHeight="56px">
                    0.015
                  </TYPE.primary>
                </Column> */}
              </RowFixed>

              {Boolean(showCrypto) && (
                <Grid>
                  {Object.keys(visibleCrypto)
                    .slice(0, 4)
                    .map((item, index) => (
                      <PoolCard key={`pool-${index}-all`} item={visibleCrypto[item]} allPairs={visibleCrypto} />
                    ))}
                </Grid>
              )}

              {!showCrypto && (
                <Row>
                  <Column style={{ alignItems: 'center', width: '100%' }}>
                    <LocalLoader />
                  </Column>
                </Row>
              )}
              <BasicLink to={`/pools/crypto`} style={{ margin: '48px auto 36px' }}>
                <ButtonGradientBorder>
                  <TYPE.primary fontSize={18} lineHeight="20px">
                    Show all
                  </TYPE.primary>
                </ButtonGradientBorder>
              </BasicLink>
            </>
          </FullWrapper>
        </PageWrapper>
      </Container>
    </>
  )
}

export default AllPairsPage
