import React from 'react'
import styled from 'styled-components'
import LogoDark from '../../assets/logo-white.svg'
import { useGlobalData } from '../../contexts/GlobalData'
import { TYPE } from '../../Theme'
import { getIxswapAppLink } from '../../utils'
import { ButtonIXSGradient } from '../ButtonStyled'
import Link, { BasicLink } from '../Link'

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  height: 50px;
  justify-content: space-between;
  position: fixed;
  padding: 10px 15px;
  top: 0;
  z-index: 3;
  display: flex;
  margin-bottom: 1px;
  background: ${({ theme }) => theme.onlyLight};
`
const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  width: fit-content;
  :hover {
    cursor: pointer;
  }
`
const IXSwapButton = styled(ButtonIXSGradient)`
  margin-right: 30px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 10px 0;
`
const IXSIcon = styled.div``

export default function Header({ hideButton = false }) {
  const { wlConfig } = useGlobalData()

  return (
    <HeaderWrapper>
      <Title as={BasicLink} to={'/home'}>
        <IXSIcon>
          <img
            width="auto"
            height="47px"
            src={wlConfig?.logoUrl || LogoDark}
            alt="logo"
            {...wlConfig?.customStyles?.logo}
          />
        </IXSIcon>
      </Title>
      {!hideButton && (
        <IXSwapButton
          as={Link}
          external
          href={wlConfig?.appUrl || getIxswapAppLink()}
          style={{ marginRight: '30px', padding: '8px 0' }}
        >
          <TYPE.header fontSize={16}>Launch {wlConfig?.name || 'IXSwap'} App ↗</TYPE.header>
        </IXSwapButton>
      )}
    </HeaderWrapper>
  )
}
