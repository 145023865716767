import React from 'react'
import { Flex } from 'rebass'
import styled from 'styled-components'
import { useGlobalData } from '../../contexts/GlobalData'
import { TYPE } from '../../Theme'
import { formattedNum, getPoolLink } from '../../utils'
import { formatAmount } from '../../utils/formatAmount'
import { PrimaryButton } from '../ButtonStyled'
import { AutoColumn } from '../Column'
import DoubleTokenLogo from '../DoubleLogo'
import Link, { BasicLink } from '../Link'
import { RowBetween } from '../Row'

export const Card = styled.div`
  max-height: 468px;
  max-width: 294px;
  border-radius: 30px;
  background: ${({ theme }) => theme.bgG2};
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  padding: 42px 24px 36px 24px;
  :hover {
    transform: translatey(-5px);
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.text6};
  opacity: 0.2;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
`

export interface PoolCardProps {
  item: any
  allPairs: any
}

export default function PoolCard({ allPairs, item }: PoolCardProps): JSX.Element {
  const pairData = allPairs[item?.id || '']

  const { wlConfig } = useGlobalData()

  return pairData ? (
    <Card>
      <Flex alignItems="center" marginBottom="34px" as={BasicLink} to={`/pools/${item?.id}`}>
        <DoubleTokenLogo size={33} a0={item.token0.id} a1={item.token1.id} />
        <TYPE.primary marginLeft="25px">{pairData.token0.symbol + '/' + pairData.token1.symbol}</TYPE.primary>
      </Flex>

      <AutoColumn as={BasicLink} to={`/pools/${item?.id}`}>
        <RowBetween marginBottom="2px">
          <TYPE.lightText>Volume</TYPE.lightText>
        </RowBetween>
        <RowBetween marginBottom="14px">
          <TYPE.primary color="customWhite"> {`${formatAmount(+Number(pairData.volumeUSD).toFixed(1))}$`}</TYPE.primary>
        </RowBetween>
      </AutoColumn>

      <AutoColumn as={BasicLink} to={`/pools/${item?.id}`}>
        <RowBetween marginBottom="2px">
          <TYPE.lightText>Price change</TYPE.lightText>
        </RowBetween>
        <RowBetween marginBottom="22px">
          <TYPE.primary color="customWhite" style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {`${formatAmount(+pairData.liquidityChangeUSD.toFixed(2), 2, 2)}%`}
          </TYPE.primary>
        </RowBetween>
      </AutoColumn>

      <Divider />

      <AutoColumn style={{ margin: '22px 0px 38px' }} as={BasicLink} to={`/pools/${item?.id}`}>
        <RowBetween display="flex" justifyContent="spaceBetween" marginBottom="8px">
          <TYPE.lightText>24H Volume</TYPE.lightText>
          <TYPE.lightText>{formattedNum(pairData.oneDayVolumeUSD.toFixed(0), true)}</TYPE.lightText>
        </RowBetween>
        <RowBetween display="flex" justifyContent="spaceBetween">
          <TYPE.lightText>TVL</TYPE.lightText>
          <TYPE.lightText>{formattedNum(pairData.reserveUSD || pairData.trackedReserveUSD, true)}</TYPE.lightText>
        </RowBetween>
      </AutoColumn>

      <Link external href={getPoolLink(item?.token0?.id, item?.token1?.id, false, wlConfig?.appUrl)}>
        <PrimaryButton>
          <TYPE.primary fontSize={18} lineHeight="20px">
            Provide liquidity
          </TYPE.primary>
        </PrimaryButton>
      </Link>
    </Card>
  ) : null
}
