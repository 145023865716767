import axios from 'axios'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react'
import { API_URL } from '../constants'

const SAVE = 'SAVE_SEC_TOKENS'

dayjs.extend(utc)

const SecTokenContext = createContext()

function useSecTokenContext() {
  return useContext(SecTokenContext)
}

function reducer(state, { type, payload }) {
  switch (type) {
    case SAVE: {
      const { secTokens } = payload
      const objectSecTokens = secTokens.reduce((accum, item) => {
        const address = item.address?.toLowerCase()
        return {
          ...accum,
          [address]: item,
        }
      }, {})
      return {
        ...state,
        ...objectSecTokens,
      }
    }
    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, {})

  const save = useCallback((secTokens) => {
    dispatch({
      type: SAVE,
      payload: {
        secTokens,
      },
    })
  }, [])

  return (
    <SecTokenContext.Provider
      value={useMemo(
        () => [
          state,
          {
            save,
          },
        ],
        [state, save]
      )}
    >
      {children}
    </SecTokenContext.Provider>
  )
}

async function getSecTokens() {
  try {
    const response = await axios.get(`${API_URL}token/list/all`)
    return response.data
  } catch (e) {
    return {
      errorMessage: e?.message,
    }
  }
}

export function Updater() {
  const [, { save }] = useSecTokenContext()
  useEffect(() => {
    async function getData() {
      const result = await getSecTokens()
      if (result?.errorMessage) {
        return
      }
      save(result)
    }
    getData()
  }, [save])
  return null
}

/**
 * Get list of sec tokens in IXSwap
 */
export function useSecTokensData() {
  const [state] = useSecTokenContext()
  return state || {}
}
