export const FACTORY_ADDRESS = '0x4983b160a8E0De9Cf6a055bd8750847DE3E14eE6'

export const BUNDLE_ID = '1'
export const API_URL = 'https://api.dev.ixswap.io/v1/'
export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  YEAR: '1 year',
  HALF_YEAR: '6 months',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  // 'https://gateway.ipfs.io/ipns/tokens.uniswap.org',
  // 'https://www.coingecko.com/tokens_list/uniswap/defi_100/v_0_0_0.json',
]

//hide from overview list
export const TOKEN_BLACKLIST = []

// pair blacklist
export const PAIR_BLACKLIST = []

// warnings to display if page contains info about blocked token
export const BLOCKED_WARNINGS = {
  '0xf4eda77f0b455a12f3eb44f8653835f377e36b76':
    'TikTok Inc. has asserted this token is violating its trademarks and therefore is not available.',
}

/**
 * For tokens that cause erros on fee calculations
 */
export const FEE_WARNING_TOKENS = ['0xd46ba6d942050d489dbd938a2c909a5d5039a161']

export const UNTRACKED_COPY = 'Derived USD values may be inaccurate without liquid stablecoin or ETH pairings.'

// tokens that should be tracked but arent due to lag in subgraph
export const TRACKED_OVERRIDES = []

export const CHAINS = {
  MAINET: 1,
  KOVAN: 42,
  POLYGON: 137,
}

export const WETH = {
  [CHAINS.MAINET]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  [CHAINS.KOVAN]: '0xd0a1e359811322d97991e03f863a0c30c2cf029c',
  [CHAINS.POLYGON]: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
}

export const usedChain = CHAINS.KOVAN
